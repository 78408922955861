import authHeader from './auth-header';

import API from '@/services/base';

class BancosService {
    async get() {
        const res = await API.get(`/bancos`, { headers: authHeader() });
        return res.data;
    }
}

export default new BancosService();
