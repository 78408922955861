import CertificadosEmpleadorService from '../services/certificados-empleador.service';

const initialState = {
    data: [],
    selected: {},
    isLoading: false,
};

export const certificadosEmpleador = {
    namespaced: true,
    state: initialState,
    actions: {
        get({ commit }) {
            return CertificadosEmpleadorService.get().then(
                res => {
                    commit('getSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('getFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        create({ commit }, { file, data }) {
            return CertificadosEmpleadorService.create({ file, data }).then(
                res => {
                    commit('createSuccess');
                    return Promise.resolve(res);
                },
                err => {
                    commit('createFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        update({ commit }, { id, data }) {
            return CertificadosEmpleadorService.update(id, { data }).then(
                res => {
                    commit('updateSuccess');
                    return Promise.resolve(res);
                },
                err => {
                    commit('updateFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        delete({ commit }, id) {
            return CertificadosEmpleadorService.delete(id).then(
                res => {
                    commit('deleteSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('deleteFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        setLoading({ commit }, value) {
            commit('setLoading', value);
        },
    },
    mutations: {
        getSuccess(state, res) {
            state.data = res.data;
        },
        getFailure(state) {
            state.data = [];
        },
        createSuccess(state) {
            //
        },
        createFailure(state) {
            //
        },
        updateSuccess(state) {
            //
        },
        updateFailure(state) {
            //
        },
        deleteSuccess(state) {
            //
        },
        deleteFailure(state) {
            //
        },
        setLoading(state, value) {
            state.isLoading = value;
        },
    }
}
