import PlanesService from '@/services/planes.service';

const initalState = {
    isLoading: false,
    data: [],
    selected: {
        intervalo: {},
    }
};

export const planes = {
    namespaced: true,
    state: initalState,
    actions: {
        get({ commit }) {
            commit('setLoading', true);
            return PlanesService.get().then(
                res => {
                    commit('getSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('getFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        show({ commit }, id) {
            return PlanesService.show(id).then(
                res => {
                    return Promise.resolve(res);
                },
                err => {
                    return Promise.reject(err.response.data);
                }
            );
        },
        select({ commit }, selected) {
            commit('select', selected);
        },
        setLoading({ commit }, value) {
            commit('setLoading', value);
        },
    },
    mutations: {
        getSuccess(state, res) {
            state.data = res.data;
            state.isLoading = false;
        },
        getFailure(state) {
            state.data = [];
            state.isLoading = false;
        },
        select(state, selected) {
            state.selected = selected;
        },
        setLoading(state, value) {
            state.isLoading = value;
        }
    }
};
