import authHeader from './auth-header';

import API from '@/services/base';

class DatosLaboralesServices {
    async createMany(data) {
        const res = await API.post(`/datos-laborales/many`, data, { headers: authHeader() });
        return res.data;
    }
}

export default new DatosLaboralesServices();
