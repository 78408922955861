import DocumentosService from '../services/documentos.service';

const initialState = {
    data: [],
    pagination: {
        page: 1,
        per_page: 10,
        total: 0,
        total_pages: 0,
    },
    stats: {
        cantidades: [],
    },
    validateRestricciones: false,
    selected: {},
    isLoading: false,
    isLoadingGetByPerfil: false,
    image: null,
    blobUrl: null,
};

export const documentos = {
    namespaced: true,
    state: initialState,
    actions: {
        get({ commit }, filters) {
            return DocumentosService.get(filters).then(
                res => {
                    commit('getSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('getFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        getStats({ commit }, cantidadDias) {
            return DocumentosService.getStats(cantidadDias).then(
                res => {
                    commit('getStatsSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('getStatsFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        getByPerfil({ commit }, filtros) {
            commit('setIsLoadingGetByPerfil', true);
            return DocumentosService.getByPerfil(filtros ? filtros : null).then(
                res => {
                    commit('getByPerfilSuccess', res.data);
                    return Promise.resolve(res.data);
                },
                err => {
                    commit('getByPerfilFailure');
                    return Promise.reject(err);
                }
            );
        },
        create({ commit }, { file, data }) {
            return DocumentosService.create({ file, data }).then(
                res => {
                    commit('createSuccess');
                    return Promise.resolve(res);
                },
                err => {
                    commit('createFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        show({ commit }, id) {
            return DocumentosService.show(id).then(
                res => {
                    commit('showSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('showFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        delete({ commit }, id) {
            return DocumentosService.delete(id).then(
                res => {
                    commit('deleteSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('deleteFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        view({ commit }, id) {
            return DocumentosService.view(id).then(
                res => {
                    commit('viewSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('viewFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        export({ commit }, data) {
            return DocumentosService.export(data);
        },
        import({ commit }, { file, data, onUploadProgress }) {
            return DocumentosService.import({
                file,
                data,
                onUploadProgress,
            }).then(
                res => {
                    commit('importSuccess');
                    return Promise.resolve(res);
                },
                err => {
                    commit('importFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        validateRestricciones({ commit }, documento_id) {
            return DocumentosService.validateRestricciones(documento_id).then(
                res => {
                    commit('validateRestriccionesSuccess');
                    return Promise.resolve(res);
                },
                err => {
                    commit('validateRestriccionesFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        sign({ commit }, { password, documento_id }) {
            return DocumentosService.sign({ password, documento_id }).then(
                res => {
                    commit('signSuccess');
                    return Promise.resolve(res);
                },
                err => {
                    commit('signFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        getAsImage({ commit }, id) {
            return DocumentosService.getAsImage(id).then(
                res => {
                    commit('getAsImageSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('getAsImageFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        selectDocumento({ commit }, documento) {
            commit('setSelectedDocumento', documento);
        },
        clean({ commit }) {
            commit('clean');
        },
        setLoading({ commit }, value) {
            commit('setLoading', value);
        },
        setIsLoadingGetByPerfil({ commit }, value) {
            commit('setIsLoadingGetByPerfil', value);
        },
    },
    mutations: {
        clean(state) {
            state.data = [];
        },
        getSuccess(state, res) {
            state.data = res.data;
            state.pagination = res.pagination;
        },
        getFailure(state) {
            state.data = [];
            state.pagination = {
                page: 1,
                per_page: 10,
                total: 0,
                total_pages: 0,
            };
        },
        getByPerfilSuccess(state, documentos) {
            state.data = documentos;
            state.isLoadingGetByPerfil = false;
        },
        getByPerfilFailure(state) {
            state.data = [];
            state.isLoadingGetByPerfil = false;
        },
        getStatsSuccess(state, res) {
            state.stats.cantidades = res.data;
        },
        getStatsFailure(state) {
            state.stats.cantidades = [];
        },
        createSuccess(state) {
            //
        },
        createFailure(state) {
            //
        },
        importSuccess(state) {
            //
        },
        importFailure(state) {
            //
        },
        signSuccess(state) {
            //
        },
        signFailure(state) {
            //
        },
        getAsImageSuccess(state, res) {
            state.image = res.documento;
        },
        getAsImageFailure(state) {
            state.image = null;
        },
        viewSuccess(state, url) {
            state.blobUrl = url;
        },
        viewFailure(state) {
            state.blobUrl = null;
        },
        showSuccess(state, res) {
            state.selected = res.data;
        },
        showFailure(state) {
            state.selected = {};
        },
        deleteSuccess(state, res) {
            //
        },
        deleteFailure(state) {
            //
        },
        setSelectedDocumento(state, documento) {
            state.selected = documento;
        },
        setLoading(state, value) {
            state.isLoading = value;
        },
        setIsLoadingGetByPerfil(state, value) {
            state.isLoadingGetByPerfil = value;
        },
        validateRestriccionesSuccess(state) {
            state.validateRestricciones = true;
        },
        validateRestriccionesFailure(state) {
            state.validateRestricciones = false;
        },
    },
};
