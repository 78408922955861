import Vue from 'vue';
import Buefy from 'buefy';
import Meta from 'vue-meta';
import Fragment from 'vue-fragment';
import VueSidebarMenu from 'vue-sidebar-menu';
import VueAnalytics from 'vue-analytics';
import Cleave from 'cleave.js';

import App from './App.vue';
import router from './router';
import store from './store';

import './styles/app.scss';

Vue.config.productionTip = false;

Vue.use(Buefy);
Vue.use(Meta);
Vue.use(Fragment.Plugin);
Vue.use(VueSidebarMenu);
Vue.use(VueAnalytics, {
    id: 'G-P6MX1E2KXS',
    router
});

Vue.directive('cleave', {
    bind(el, binding) {
        const input = el.querySelector('input');
        input._vCleave = new Cleave(input, binding.value);
    },
    unbind(el) {
        const input = el.querySelector('input');
        input._vCleave.destroy();
    }
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
