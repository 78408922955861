const initialState = {
    sidebar: true,
    modals: {
        cambiarEmail: false,
        cambiarContrasena: false,
        seleccionarPerfil: false,
        subirImagen: false,
        avisoRegistrarEmpresa: false,
    }
};

export const ui = {
    namespaced: true,
    state: initialState,
    actions: {
        toggleSidebar({ commit }, value){
            commit('toggleSidebar', value);
        },
        setIsModalCambiarEmail({ commit }, isActive) {
            commit('setIsModalCambiarEmail', isActive);
        },
        setIsModalCambiarContrasena({ commit }, isActive) {
            commit('setIsModalCambiarContrasena', isActive)
        },
        setisModalSeleccionarPerfil({ commit }, isActive) {
            commit('setIsModalSeleccionarPerfil', isActive);
        },
        setIsModalSubirImagen({ commit }, isActive) {
            commit('setIsModalSubirImagen', isActive);
        },
        setIsModalAvisoRegistrarEmpresa({ commit }, isActive) {
            commit('setIsModalAvisoRegistrarEmpresa', isActive);
        }
    },
    mutations: {
        toggleSidebar(state, value) {
            state.sidebar = value;
        },
        setIsModalCambiarEmail(state, isActive) {
            state.modals.cambiarEmail = isActive;
        },
        setIsModalCambiarContrasena(state, isActive) {
            state.modals.cambiarContrasena = isActive;
        },
        setIsModalSeleccionarPerfil(state, isActive) {
            state.modals.seleccionarPerfil = isActive;
        },
        setIsModalSubirImagen(state, isActive) {
            state.modals.subirImagen = isActive;
        },
        setIsModalAvisoRegistrarEmpresa(state, isActive) {
            state.modals.avisoRegistrarEmpresa = isActive;
        }
    }
};
