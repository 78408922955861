<template>
    <section class="hero is-fullheight">
        <div
            class="hero-body has-text-centered is-flex-direction-column is-justify-content-center"
        >
            <div>
                <img
                    class="center-img"
                    :src="require('@/assets/images/layout/error.png')"
                />
                <h2 class="title">
                    <span class="title has-text-primary-dark">404</span> Página no encontrada
                </h2>
                <router-link class="subtitle" to="/">
                    <b-icon icon="arrow-left-bold" size="is-small"></b-icon> Volver a Inicio
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'PageNotFound'
};
</script>

<style lang="scss" scoped>
.center-img {
    margin: auto;
    width: 80%;
    max-width: 300px;
}
</style>
