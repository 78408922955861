import CamposService from '../services/campos-datos-laborales.service';

const initialState = {
    data: [],
    selected: {},
    isLoading: false,
    isLoadingSelected: false,
};

export const camposDatosLaborales = {
    namespaced: true,
    state: initialState,
    actions: {
        create({ commit }, { descripcion, opciones }) {
            return CamposService.create({ descripcion, opciones }).then(
                res => {
                    return Promise.resolve(res);
                },
                err => {
                    return Promise.reject(err.response.data);
                }
            );
        },
        update({ commit }, { id, descripcion, opciones }) {
            return CamposService.update(id, { descripcion, opciones }).then(
                res => {
                    return Promise.resolve(res);
                },
                err => {
                    return Promise.reject(err.response.data);
                }
            );
        },
        get({ commit }) {
            commit('setIsLoading', true);
            return CamposService.get().then(
                res => {
                    commit('getSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('getFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        show({ commit }, id) {
            commit('setIsLoadingSelected', true);
            return CamposService.show(id).then(
                res => {
                    commit('showSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('showFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        select({ commit }, id) {
            commit('select', id);
        },
        setIsLoading({ commit }, value) {
            commit('setIsLoading', value);
        },
        setIsLoadingSelected({ commit }, value) {
            commit('setIsLoadingSelected', value);
        }
    },
    mutations: {
        createSuccess() {
            
        },
        createFailure() {

        },
        getSuccess(state, res) {
            state.data = res.data;
            state.isLoading = false;
        },
        getFailure(state) {
            state.data = [];
            state.isLoading = false;
        },
        showSuccess(state, res) {
            state.selected = res.data;
            state.isLoadingSelected = false;
        },
        showFailure(state) {
            state.selected = {};
            state.isLoadingSelected = false;
        },
        select(state, id) {
            state.selected = state.data.find(campo => campo.id === id);
        },
        setIsLoading(state, value) {
            state.isLoading = value;
        },
        setIsLoadingSelected(state, value) {
            state.isLoadingSelected = value;
        }
    }
}
