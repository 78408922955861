import authHeader from './auth-header';

import API from '@/services/base';

class CertificadosEmpleadorService {
    async get() {
        const res = await API.get(`/certificados-empleador`, { headers: authHeader() });
        return res.data;
    }

    async create({ file, data }) {
        const formData = new FormData();
        formData.append('file', file);

        for (const key in data) {
            formData.append(key, data[key]);
        }

        const headers = {
            ...authHeader(),
            'Content-Type': 'multipart-form-data'
        };

        const res = await API.post(`/certificados-empleador`, formData, { headers });
        return res.data;
    }

    async update(id, { data }) {
        const headers = {
            ...authHeader(),
        };

        const res = await API.put(`/certificados-empleador/${id}`, { ...data }, { headers });
        return res.data;
    }

    async delete(id) {
        const res = await API.delete(`/certificados-empleador/${id}`, { headers: authHeader() });
        return res.data;
    }
}

export default new CertificadosEmpleadorService();
