import TrabajadoresService from '../services/trabajadores.service';

const initialState = {
    selected: {
        persona: {},
        usuario: {},
        trabajador: {},
        datos_laborales: [],
    },
    es_admin: false,
    isLoading: false,
    existeTrabajadorResponse: {
        existe: null,
        message: ''
    }
};

export const trabajadores = {
    namespaced: true,
    state: initialState,
    actions: {
        create({ commit }, { persona, usuario, trabajador }) {
            return TrabajadoresService.create({ persona, usuario, trabajador }).then(
                res => {
                    commit('createSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('createFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        update({ commit }, { usuario, trabajador }) {
            return TrabajadoresService.update({ usuario, trabajador }).then(
                res => {
                    return Promise.resolve(res);
                },
                err => {
                    return Promise.reject(err.response.data);
                }
            );
        },
        existe({ commit }, persona) {
            return TrabajadoresService.existe(persona).then(
                res => {
                    commit('existeSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('existeFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        verifyHasDocument({ commit }, data) {
            return TrabajadoresService.verifyHasDocument(data).then(
                res => {
                    return Promise.resolve(res);
                },
                err => {
                    return Promise.reject(err.response.data);
                }
            );
        },
        import({ commit }, file) {
            return TrabajadoresService.import(file).then(
                res => {
                    commit('importSuccess');
                    return Promise.resolve(res);
                },
                err => {
                    commit('importFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        show({ commit }, dni) {
            commit('setIsLoading', true);
            return TrabajadoresService.show(dni).then(
                res => {
                    commit('showSuccess', res.data);
                    return Promise.resolve(res.data);
                },
                err => {
                    commit('showFailure');
                    return Promise.reject(err);
                }
            );
        },
        permisos({ commit }, id) {
            return TrabajadoresService.permisos(id).then(
                res => {
                    commit('permisosSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('permisosFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        updatePermisos({ commit }, { id, data }) {
            return TrabajadoresService.updatePermisos(id, data).then(
                res => {
                    return Promise.resolve(res);
                },
                err => {
                    return Promise.reject(err.response.data);
                }
            );
        },
        export({ commit }, data) {
            return TrabajadoresService.export(data);
        },
        downloadImportStructure() {
            return TrabajadoresService.downdloadImportStructure();
        },
        setIsLoading({ commit }, value) {
            commit('setIsLoading', value);
        }
    },
    mutations: {
        createSuccess(state, res) {
            //
        },
        createFailure(state) {
            //
        },
        importSuccess(state) {
            //
        },
        importFailure(state) {
            //
        },
        permisosSuccess(state, res) {
            state.es_admin = res.data.es_admin;
        },
        permisosFailure(state) {
            state.es_admin = false;
        },
        existeSuccess(state, res) {
            state.existeTrabajadorResponse.existe = res.existe;
            state.existeTrabajadorResponse.message = res.message;
        },
        existeFailure(state) {
            state.existeTrabajadorResponse.existe = null;
            state.existeTrabajadorResponse.message = '';
        },
        showSuccess(state, selected) {
            state.selected = selected;
            state.isLoading = false;
        },
        getTrabajadorFailure(state) {
            state.selected = {};
            state.isLoading = false;
        },
        setIsLoading(state, value) {
            state.isLoading = value;
        },
    }
}
