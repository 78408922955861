import authHeader from './auth-header';

import API from '@/services/base';

class EmpresasService {
    async create(data) {
        const res = await API.post(`/empresas`, data, { headers: authHeader() });
        return res.data;
    }

    async getStats() {
        const res = await API.get(`/empresas/stats`, { headers: authHeader() });
        return res.data;
    }

    async update(data) {
        const res = await API.put(`/empresas`, data, { headers: authHeader() });
        return res.data;
    }

    async getEmpleados(filters) {
        // const filtersEncoded = btoa(JSON.stringify(filters));
        const res = await API.get(`/empresas/empleados`, { headers: authHeader(), params: filters });
        return res.data;
    }
}

export default new EmpresasService();
