import authHeader from './auth-header';
import API from '@/services/base';

class UsuariosService {
    resetPassword(id, data) {
        return API
            .post(`/usuarios/${id}/reset-password`, data, { headers: authHeader() })
            .then(res => {
                return res.data;
            });
    }

    getUsernameAvailability(username) {
        return API
            .post(`/usuarios/get-username-availability`, { username })
            .then(res => {
                return res.data;
            });
    }

    getInitialsAvatar() {
        return API
            .get(`/usuarios/get-initials-avatar`, { headers: authHeader() })
            .then(res => {
                return res.data;
            });
    }

    createOtherUser() {
        return API.post(`/usuarios/`, { headers: authHeader() })
            .then(res => {
                return res.data;
            })
            .catch(err => {
                return err.response.data;
            });
    }

    importar(file) {
        const formData = new FormData();
        formData.append('file', file);

        return API
            .post(`/usuarios/importar`, formData, {
                headers: {
                    ...authHeader(),
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                return res.data;
            });
    }
}

export default new UsuariosService();
