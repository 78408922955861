import authHeader from './auth-header';
import API from '@/services/base';

class TipoProcesosService {
    get() {
        return API.get(`/tipos-procesos`, { headers: authHeader() }).then(res => {
            return res.data;
        });
    }
}

export default new TipoProcesosService();
