import TiposProcesos from '../services/tipos-procesos.service';

const initialState = {
    data: [],
    isLoading: false
};

export const tiposProcesos = {
    namespaced: true,
    state: initialState,
    actions: {
        get({ commit }) {
            commit('setIsLoading', true);
            return TiposProcesos.get().then(
                res => {
                    commit('getSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('getFailure');
                    return Promise.reject(err);
                }
            );
        },
        setIsLoading({ commit }, value) {
            commit('setIsLoading', value);
        }
    },
    mutations: {
        setIsLoading(state, value) {
            state.isLoading = value;
        },
        getSuccess(state, res) {
            state.data = res.data;
            state.isLoading = false;
        },
        getFailure(state) {
            state.data = [];
            state.isLoading = false;
        }
    }
}
