import authHeader from './auth-header';

import API from '@/services/base';

class ConfiguracionesFirmaService {
    async showLast(data) {
        const res = await API.get(`/configuraciones-firma/last`, { headers: authHeader(), params: data });
        return res.data;
    }

    async create(data) {
        const headers = {
            ...authHeader(),
        };

        const res = await API.post(`/configuraciones-firma`, { ...data }, { headers });
        return res.data;
    }

    async update(id, { data }) {
        const headers = {
            ...authHeader(),
        };

        const res = await API.put(`/configuraciones-firma/${id}`, { ...data }, { headers });
        return res.data;
    }
}

export default new ConfiguracionesFirmaService();
