import ConfiguracionesFirmaService from '../services/configuraciones-firma.service';

const initialState = {
    data: [],
    last: {
        EMPLEADOR: {},
        TRABAJADOR: {}
    },
    isLoading: false,
    form: {
        id: 0,
        num_page: 1,
        pos_x: '',
        pos_y: '',
        w: '',
        h: '',
    }
};

export const configuracionesFirma = {
    namespaced: true,
    state: initialState,
    actions: {
        showLast({ commit }, data) {
            return ConfiguracionesFirmaService.showLast(data).then(
                res => {
                    commit('showLastSuccess', { res, firmante: data.firmante });
                    return Promise.resolve(res);
                },
                err => {
                    commit('showLastFailure', data.firmante);
                    return Promise.reject(err.response.data);
                }
            );
        },
        create({ commit }, data) {
            return ConfiguracionesFirmaService.create(data).then(
                res => {
                    commit('createSuccess');
                    return Promise.resolve(res);
                },
                err => {
                    commit('createFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        update({ commit }, { id, data }) {
            return ConfiguracionesFirmaService.update(id, { data }).then(
                res => {
                    commit('updateSuccess');
                    return Promise.resolve(res);
                },
                err => {
                    commit('updateFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        setLoading({ commit }, value) {
            commit('setLoading', value);
        },
    },
    mutations: {
        showLastSuccess(state, { res, firmante }) {
            state.last[firmante] = res.data ?? {};
        },
        showLastFailure(state, firmante) {
            state.last[firmante] = {};
        },
        createSuccess(state) {
            //
        },
        createFailure(state) {
            //
        },
        updateSuccess(state) {
            //
        },
        updateFailure(state) {
            //
        },
        setLoading(state, value) {
            state.isLoading = value;
        },
        updateFormId(state, value) {
            state.form.id = value;
        },
        updateFormNumPage(state, value) {
            state.form.num_page = value;
        },
        updateFormPosX(state, value) {
            state.form.pos_x = value;
        },
        updateFormPosY(state, value) {
            state.form.pos_y = value;
        },
        updateFormW(state, value) {
            state.form.w = value;
        },
        updateFormH(state, value) {
            state.form.h = value;
        }
    }
}
