import Utils from '@/utils/Utils';
import authHeader from './auth-header';
import API from '@/services/base';

class TrabajadoresService {
    async create({ persona, usuario, trabajador }) {
        const res = await API.post(`/trabajadores`, { persona, usuario, trabajador }, { headers: authHeader() });
        return res.data;
    }

    async update({ usuario, trabajador }) {
        const res = await API.put(`/trabajadores/${trabajador.id}`, { usuario, trabajador }, { headers: authHeader() });
        return res.data;
    }

    async existe(persona) {
        const res = await API.post(`/trabajadores/existe`, persona, { headers: authHeader() });
        return res.data;
    }

    async show(dni) {
        const res = await API.get(`/trabajadores/${dni}`, { headers: authHeader() });
        return res.data;
    }

    async verifyHasDocument(data) {
        const res = await API.post(`/trabajadores/verify-has-document`, data, { headers: authHeader() });
        return res.data;
    }

    async permisos(id) {
        const res = await API.get(`/trabajadores/${id}/permisos`, { headers: authHeader() });
        return res.data;
    }

    async updatePermisos(id, data) {
        const res = await API.put(`/trabajadores/${id}/permisos`, data, { headers: authHeader() });
        return res.data;
    }

    async import(file) {
        const formData = new FormData();
        formData.append('file', file);

        const headers = {
            ...authHeader(),
            'Content-Type': 'multipart-form-data'
        };

        const res = await API.post(`/trabajadores/importar`, formData, { headers });
        return res.data;
    }

    async export({ format }) {
        const res = await API.post(`/trabajadores/exportar`, { format }, { responseType: 'blob', headers: authHeader() });
        Utils.downloadFileFromResponse(res, 'trabajadores', format);
    }

    async downdloadImportStructure() {
        const res = await API.get(`/trabajadores/importar/estructura`, { responseType: 'blob', headers: authHeader() });
        Utils.downloadFileFromResponse(res, 'estructura-imporacion', 'csv');
    }
}

export default new TrabajadoresService();
