import UsuariosService from '@/services/usuarios.service';

const initialState = {
    initialsAvatar: '',
    usernameAvailabilityResponse: {
        availability: null,
        message: '',
    },
};

export const usuarios = {
    namespaced: true,
    state: initialState,
    actions: {
        resetPassword({ commit }, { id, ...data }) {
            return UsuariosService.resetPassword(id, data).then(
                res => {
                    return Promise.resolve(res);
                },
                err => {
                    return Promise.reject(err.response.data);
                }
            );
        },
        getUsernameAvailability({ commit }, username) {
            return UsuariosService.getUsernameAvailability(username).then(
                res => {
                    commit('getUsernameAvailabilitySuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('getUsernameAvailibilityFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        importar({ commit }, file) {
            return UsuariosService.importar(file).then(
                res => {
                    commit('importarSuccess');
                    return Promise.resolve(res);
                },
                err => {
                    commit('importarFailure');
                    return Promise.reject(err);
                }
            );
        },
        getInitialsAvatar({ commit }) {
            return UsuariosService.getInitialsAvatar().then(
                res => {
                    commit('getInitialsAvatarSuccess', res.data);
                    return Promise.resolve(res.data);
                },
                err => {
                    commit('getInitialsAvatarFailure');
                    return Promise.reject(err);
                }
            );
        },
    },
    mutations: {
        getInitialsAvatarSuccess(state, avatar) {
            state.initialsAvatar = avatar;
        },
        getInitialsAvatarFailure(state) {
            state.initialsAvatar = '';
        },
        importarSuccess(state) {
            //
        },
        importarFailure(state) {
            //
        },
        getUsernameAvailabilitySuccess(state, data) {
            state.usernameAvailabilityResponse.availability = data.data.disponibilidad;
            state.usernameAvailabilityResponse.message = data.message;
        },
        getUsernameAvailibilityFailure(state) {
            state.usernameAvailabilityResponse.availability = false;
            state.usernameAvailabilityResponse.message = '';
        },
    }
};
