import Utils from '@/utils/Utils';
import authHeader from './auth-header';
import API from '@/services/base';

class DocumentosService {
    async get(filters) {
        const res = await API.get(`/documentos`, {
            headers: authHeader(),
            params: filters,
        });
        return res.data;
    }

    async getStats(cantidadDias) {
        const res = await API.get(`/documentos/stats?d=${cantidadDias}`, {
            headers: authHeader(),
        });
        return res.data;
    }

    async getByPerfil(filtros) {
        const filtrosEncoded = btoa(JSON.stringify(filtros));
        const res = await API.get(`/documentos/perfil?fe=${filtrosEncoded}`, {
            headers: authHeader(),
        });
        return res.data;
    }

    async getAsImage(id) {
        const res = await API.get(`/documentos/${id}/as-image`, {
            headers: authHeader(),
        });
        return res.data;
    }

    async create({ file, data }) {
        const formData = new FormData();
        formData.append('file', file);

        for (const key in data) {
            formData.append(key, data[key]);
        }

        const headers = {
            ...authHeader(),
            'Content-Type': 'multipart-form-data',
        };

        const res = await API.post(`/documentos`, formData, { headers });
        return res.data;
    }

    async validateRestricciones(documento_id) {
        const res = await API.get(`/documentos/validate-restricciones`, {
            headers: authHeader(),
            params: { documento_id },
        });
        return res.data;
    }

    async sign({ password, documento_id }) {
        const res = await API.post(
            `/documentos/sign`,
            { password, documento_id },
            { headers: authHeader() }
        );
        return res.data;
    }

    async import({
        file,
        data,
        onUploadProgress = function(progressEvent) {},
    }) {
        const formData = new FormData();
        formData.append('file', file);

        for (const key in data) {
            formData.append(key, data[key]);
        }

        const headers = {
            ...authHeader(),
            'Content-Type': 'multipart-form-data',
        };

        const res = await API.post(`/documentos/importar`, formData, {
            headers,
            onUploadProgress,
        });
        return res.data;
    }

    async view(id) {
        const res = await API.get(`/documentos/view/${id}`, {
            responseType: 'blob',
            headers: {
                ...authHeader(),
                'Cache-Control': 'no-cache',
            },
        });
        const url = Utils.urlFileFromResponse(res);

        return url;
    }

    async show(id) {
        const res = await API.get(`/documentos/${id}`, {
            headers: authHeader(),
        });
        return res.data;
    }

    async delete(id) {
        const res = await API.delete(`/documentos/${id}`, {
            headers: authHeader(),
        });
        return res.data;
    }

    async export({ format, filters }) {
        const res = await API.post(
            `/documentos/exportar`,
            { format, filters },
            { responseType: 'blob', headers: authHeader() }
        );
        Utils.downloadFileFromResponse(res, 'documentos', format);
    }
}

export default new DocumentosService();
