import TiposDocumentosEjemplosService from '../services/tipos-documentos-ejemplos.service';

const initialState = {
    isLoading: false,
    blobUrl: null,
};

export const tiposDocumentosEjemplos = {
    namespaced: true,
    state: initialState,
    actions: {
        create({ commit }, { file, data }) {
            return TiposDocumentosEjemplosService.create({ file, data }).then(
                res => {
                    commit('createSuccess');
                    return Promise.resolve(res);
                },
                err => {
                    commit('createFailure');
                    return Promise.reject(err.response.data);
                }
            )
        },
        setIsLoading({ commit }, value) {
            commit('setIsLoading', value);
        }
    },
    mutations: {
        createSuccess() {
            //
        },
        createFailure() {
            //
        },
        setIsLoading(state, value) {
            state.isLoading = value;
        },
    }
}
