import authHeader from './auth-header';

import API from '@/services/base';

class AuthService {
    login({ username, password, recaptcha_token }) {
        return API
            .post(`/auth/login`, {
                username,
                password,
                recaptcha_token
            })
            .then(res => {
                if (res.data.token && res.data.access && !res.data.temporal) {
                    localStorage.setItem(
                        'token',
                        JSON.stringify(res.data.token)
                    );
                }
                return res.data;
            });
    }

    signup(user) {
        return API.post(`/auth/signup`, user).then(res => {
            if (res.data.token) {
                localStorage.setItem('token', JSON.stringify(res.data.token));
            }
            return res.data;
        });
    }

    logout() {
        return API.post(`/auth/logout`, {}, { headers: authHeader() }).then(res => {
            localStorage.removeItem('token');
            return res.data;
        });
    }

    refresh() {
        return API.post('/auth/refresh', {}, { headers: authHeader() }).then(res => {
            if (res.data.token) {
                localStorage.setItem('token', JSON.stringify(res.data.token));
            }
            return res.data;
        });
    }

    perfil() {
        return API
            .get(`/auth/perfil`, { headers: authHeader() })
            .then(res => {
                return res.data;
            });
    }

    // En este caso es necesario usar el token temporal ya que el de localStorage aun no ha sido grabado
    changePerfil({ perfil_id }, temporalToken) {
        return API
            .post(
                `/auth/change-perfil`,
                { perfil_id },
                { headers: { Authorization: `Bearer ${temporalToken}` } }
            )
            .then(res => {
                if (res.data.token && res.data.access) {
                    localStorage.setItem(
                        'token',
                        JSON.stringify(res.data.token)
                    );
                }
                return res.data;
            });
    }

    async firstLogin(data, temporalToken) {
        const res = await API.post(`/auth/first-login`, data, {
            headers: { Authorization: `Bearer ${temporalToken}` }
        });
        return res.data;
    }

    async forgotPassword(data) {
        const res = await API.post(`/auth/forgot-password`, data);
        return res.data;
    }

    async sendResetCode(data) {
        const res = await API.post('/auth/verify-code', data);
        return res.data;
    }

    async resetPassword(data, temporalToken) {
        const res = await API.post('/auth/reset-password', data, {
            headers: { Authorization: `Bearer ${temporalToken}` }
        });
        return res.data;
    }
}

export default new AuthService();
