import SelfService from '../services/me.service';

const initalState = {
    perfil: {
        trabajador: {
            usuario: {
                persona: {
                    pais: {},
                },
            },
            empresa: {
                pais: {}
            }
        }
    },
    info: {
        personal: {},
        laboral: []
    },
    stats: {},
    img: null,
    perfiles: [],
    isLoading: false,
};

export const me = {
    namespaced: true,
    state: initalState,
    actions: {
        get({ commit }) {
            commit('setIsLoading', true);
            return SelfService.get().then(
                res => {
                    commit('getSuccess', res.data);
                    return Promise.resolve(res.data);
                },
                error => {
                    commit('getFailure');
                    return Promise.reject(error.response.data);
                }
            );
        },
        getInfo({ commit }) {
            return SelfService.getInfo().then(
                res => {
                    commit('getInfoSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('getInfoFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        getImage({ commit }) {
            return SelfService.getImage().then(
                res => {
                    commit('getImageSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('getImageFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        empresa({ commit }) {
            return SelfService.empresa().then(
                res => {
                    return Promise.resolve(res);
                },
                err => {
                    return Promise.reject(err.response.data);
                }
            );
        },
        getStats({ commit }) {
            return SelfService.getStats().then(
                res => {
                    commit('getStatsSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('getStatsFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        getPerfiles({ commit }) {
            return SelfService.getPerfiles().then(
                res => {
                    commit('getPerfilesSuccess', res.data);
                    return Promise.resolve(res.data);
                },
                error => {
                    commit('getPerfilesFailure');
                    return Promise.reject(error);
                }
            );
        },
        update({ commit }, data) {
            return SelfService.update(data).then(
                res => {
                    commit('updateSuccess');
                    return Promise.resolve(res);
                },
                err => {
                    commit('updateFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        updateEmail({ commit }, data) {
            return SelfService.updateEmail(data).then(
                res => {
                    commit('updateSuccess');
                    return Promise.resolve(res);
                },
                err => {
                    commit('updateFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        updatePassword({ commit }, data) {
            return SelfService.updatePassword(data).then(
                res => {
                    commit('updateSuccess');
                    return Promise.resolve(res);
                },
                err => {
                    commit('updateFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        uploadImage({ commit }, data) {
            return SelfService.uploadImage(data).then(
                res => {
                    return Promise.resolve(res);
                },
                err => {
                    return Promise.reject(err.response.data);
                }
            );
        },
        setPerfiles({ commit }, perfiles) {
            commit('setPerfiles', perfiles);
        },
    },
    mutations: {
        getSuccess(state, me) {
            state.perfil = me.perfil;
            state.isLoading = false;
        },
        getFailure(state) {
            state = null;
            state.isLoading = false;
        },
        getInfoSuccess(state, res) {
            state.info = res.data;
        },
        getInfoFailure(state) {
            state.info = { personal: {}, laboral: [] };
        },
        getStatsSuccess(state, res) {
            state.stats = res.data;
        },
        getStatsFailure(state) {
            state.stats = {};
        },
        getPerfilesSuccess(state, data) {
            state.perfiles = data;
        },
        getPerfilesFailure(state) {
            state.perfiles = [];
        },
        getImageSuccess(state, res) {
            state.img = res.img;
        },
        getImageFailure(state) {
            state.img = null;
        },
        updateSuccess(state) {
            //
        },
        updateFailure(state) {
            //
        },
        setPerfiles(state, perfiles) {
            state.perfiles = perfiles;
        },
        setIsLoading(state, value) {
            state.isLoading = value;
        },
    },
};
