import authHeader from './auth-header';
import API from '@/services/base';

class PlanesService {
    async get() {
        const res = await API.get(`/planes`, { headers: authHeader() });
        return res.data;
    }

    async show(id) {
        const res = await API.get(`/planes/${id}`, { headers: authHeader() });
        return res.data;
    }
}

export default new PlanesService();
