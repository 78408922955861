import PagosService from '@/services/pagos.service';

const initialState = {
    data: [],
};

export const pagos = {
    namespaced: true,
    state: initialState,
    actions: {
        sessionToken({ commit }, data) {
            return PagosService.sessionToken(data).then(
                res => {
                    /* commit('sessionTokenSuccess', res); */
                    return Promise.resolve(res);
                },
                err => {
                    /* commit('') */
                    return Promise.reject(err.response.data);
                }
            );
        },
        procesar({ commit }, data) {
            return PagosService.procesar(data).then(
                res => {
                    commit('procesarSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('procesarFailure');
                    return Promise.reject(err.response.data);
                }
            );
        }
    },
    mutations: {
        sessionTokenSuccess() {
            //
        },
        sessionTokenFailure() {
            //
        }
    }
};
