import Vue from 'vue';
import Vuex from 'vuex';

import { auth } from './auth.module';
import { me } from './me.module';
import { tiposDocumentos } from './tipos-documentos.module';
import { documentos } from './documentos.module';
import { ui } from './ui.module';
import { paises } from './paises.module';
import { empresas } from './empresas.module';
import { trabajadores } from './trabajadores.module';
import { usuarios } from './usuarios.module';
import { camposDatosLaborales } from './campos-datos-laborales.module';
import { datosLaborales } from './datos-laborales.module';
import { estados } from './estados.module';
import { procesos } from './procesos.module';
import { tiposProcesos } from './tipos-procesos.module';
import { cargasDocumentos } from './cargas-documentos.module';
import { planes } from './planes.module';
import { pagos } from './pagos.module';
import { certificadosEmpleador } from './certificados-empleador.module';
import { configuracionesFirma } from './configuraciones-firma.module';
import { tiposDocumentosEjemplos } from './tipos-documentos-ejemplos.module';
import { bancos } from './bancos.module';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        me,
        tiposDocumentos,
        documentos,
        paises,
        ui,
        empresas,
        trabajadores,
        usuarios,
        camposDatosLaborales,
        estados,
        procesos,
        tiposProcesos,
        cargasDocumentos,
        datosLaborales,
        planes,
        pagos,
        certificadosEmpleador,
        configuracionesFirma,
        tiposDocumentosEjemplos,
        bancos,
    },
});
