import ProcesosService from '../services/procesos.service';

const initialState = {
    data: [],
    active: null,
    isLoading: false
};

export const procesos = {
    namespaced: true,
    state: initialState,
    actions: {
        get({ commit }, slug = null) {
            commit('setIsLoading', true);
            return ProcesosService.get(slug).then(
                res => {
                    commit('getSuccess', res);
                    commit('setIsLoading', false);
                    return Promise.resolve(res);
                },
                err => {
                    commit('getFailure');
                    commit('setIsLoading', false);
                    return Promise.reject(err);
                }
            );
        },
        getActive({ commit }, slug = null) {
            return ProcesosService.getActive(slug).then(
                res => {
                    commit('getActiveSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('getActiveFailure');
                    return Promise.reject(err);
                }
            );
        },
        exportResultados({ commit }, proceso_id) {
            return ProcesosService.exportResultados(proceso_id);
        },
        setActive({ commit }, proceso) {
            commit('setActive', proceso);
        }
    },
    mutations: {
        setIsLoading(state, value) {
            state.isLoading = value;
        },
        getSuccess(state, res) {
            state.data = res.data;
        },
        getFailure(state) {
            state.data = [];
        },
        getActiveSuccess(state, res) {
            state.active = res.data;
        },
        getActiveFailure(state) {
            state.active = {};
        },
        setActive(state, proceso) {
            state.active = proceso;
        }
    }
}
