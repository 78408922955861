import AuthService from '@/services/auth.service';
import { error } from 'jquery';

const token = JSON.parse(localStorage.getItem('token'));

const intialState = token
    ? { status: { loggedIn: true }, perfil: null, perfiles: [], token, expiration: null, now: null }
    : { status: { loggedIn: false }, perfil: null, perfiles: [], token: null, expiration: null, now: null };

export const auth = {
    namespaced: true,
    state: intialState,
    actions: {
        login({ commit }, user) {
            return AuthService.login(user).then(
                user => {
                    commit('loginSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error.response.data);
                }
            );
        },
        signup({ commit }, user) {
            return AuthService.signup(user).then(
                res => {
                    commit('signupSuccess', res);
                    return Promise.resolve(res);
                },
                error => {
                    commit('signupFailure');
                    return Promise.reject(error.response.data);
                }
            );
        },
        logout({ commit }) {
            return AuthService.logout().then(
                res => {
                    commit('logoutSuccess');
                    return Promise.resolve(res);
                },
                error => {
                    commit('logoutFailure');
                    return Promise.reject(error.response.data);
                }
            );
        },
        refresh({ commit }) {
            return AuthService.refresh().then(
                res => {
                    commit('refreshSuccess', res);
                    return Promise.resolve(res);
                },
                error => {
                    commit('refreshFailure');
                    return Promise.reject(error.response.data);
                }
            );
        },
        perfil({ commit }) {
            commit('perfilSuccess');
            return AuthService.perfil().then(
                res => {
                    commit('perfilSuccess', res);
                    return Promise.resolve(res);
                },
                error => {
                    commit('perfilFailure', error);
                    return Promise.reject(error);
                }
            );
        },
        changePerfil({ commit }, { perfil_id, temporalToken }) {
            return AuthService.changePerfil({ perfil_id }, temporalToken).then(
                res => {
                    commit('loginSuccess', res);
                    return Promise.resolve(res);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        firstLogin({ commit }, { temporalToken, ...data }) {
            return AuthService.firstLogin(data, temporalToken).then(
                res => {
                    return Promise.resolve(res);
                },
                err => {
                    return Promise.reject(err.response.data);
                }
            );
        },
        forgotPassword({ commit }, { email }) {
            return AuthService.forgotPassword({ email }).then(
                res => {
                    return Promise.resolve(res);
                },
                err => {
                    return Promise.reject(err.response.data);
                }
            );
        },
        sendResetCode({ commit }, { codigo }) {
            return AuthService.sendResetCode({ codigo }).then(
                res => {
                    return Promise.resolve(res);
                },
                err => {
                    return Promise.reject(err.response.data);
                }
            );
        },
        resetPassword({ commit }, { password, password_confirmation, temporalToken }) {
            return AuthService.resetPassword({ password, password_confirmation }, temporalToken).then(
                res => {
                    return Promise.resolve(res);
                },
                err => {
                    return Promise.reject(err.response.data);
                }
            );
        }
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.token = user.token;
            state.perfiles = user.perfiles;
            state.expiration = user.expiration;
            state.now = user.now;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.token = null;
            state.perfiles = [];
        },
        signupSuccess(state, res) {
            state.token = null;
        },
        signupFailure(state) {
            state.token = null;
        },
        logoutSuccess(state) {
            state.status.loggedIn = false;
            state.token = null;
            state.perfiles = [];
        },
        logoutFailure(state) {
            //
        },
        refreshSuccess(state, data) {
            state.token = data.token;
            state.expiration = data.expiration;
            state.now = data.now;
        },
        refreshFailure(state) {
            //
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        },
        perfilSuccess(state, perfil) {
            state.perfil = perfil;
            state.perfiles = [];
        },
        perfilFailure(state) {
            state.perfil = null;
        }
    }
};
