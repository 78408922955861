export default function authHeader() {
    let token = JSON.parse(localStorage.getItem('token'));

    if (token) {
        return { Authorization: `Bearer ${token}`, Accept: 'application/json' };
    } else {
        return {
            Accept: 'application/json'
        };
    }
}
