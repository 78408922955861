import BancosService from '../services/bancos.service';

export const bancos = {
    namespaced: true,
    state: {
        data: [],
    },
    actions: {
        get({ commit }) {
            return BancosService.get().then(
                res => {
                    commit('getSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('getFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
    },
    mutations: {
        getSuccess(state, res) {
            state.data = res.data;
        },
        getFailure(state) {
            state.data = [];
        },
    },
};
