import Utils from '@/utils/Utils';
import authHeader from './auth-header';
import API from '@/services/base';

class TiposDocumentosService {
    get(params) {
        return API.get(`/tipos-documentos`, { headers: authHeader(), params }).then(res => {
            return res.data;
        });
    }

    show(id) {
        return API.get(`/tipos-documentos/${id}`, { headers: authHeader() }).then(res => {
            return res.data;
        });
    }

    getStats(id) {
        return API.get(`/tipos-documentos/${id}/stats`, { headers: authHeader() }).then(res => {
            return res.data;
        });
    }

    create(data) {
        return API.post(`/tipos-documentos`, data, { headers: authHeader() }).then(res => {
            return res.data;
        });
    }

    update(id, data) {
        return API.put(`/tipos-documentos/${id}`, { ...data }, { headers: authHeader() }).then(res => {
            return res.data;
        });
    }

    verifyAptoParaSubir(id) {
        return API.get(`/tipos-documentos/${id}/verify-apto-para-subir`, { headers: authHeader() }).then(res => {
            return res.data;
        });
    }

    async viewEjemplo(id, data) {
        const res = await API.get(`/tipos-documentos/${id}/view-ejemplo`, {
            params: {
                ...data
            },
            responseType: 'blob',
            headers: {
                ...authHeader(),
                'Cache-Control': 'no-cache'
            }
        });
        const url = Utils.urlFileFromResponse(res, 'image/jpg');

        return url;
    }
}

export default new TiposDocumentosService();
