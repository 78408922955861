import authHeader from './auth-header';

import API from '@/services/base';

class CargasDocumentosService {
    async get() {
        const res = await API.get(`/cargas-documentos`, { headers: authHeader() });
        return res.data;
    }

    async show(codigo) {
        const res = await API.get(`/cargas-documentos/${codigo}/search`, { headers: authHeader() });
        return res.data;
    }

    async destroy(id, data) {
        const res = await API.delete(`/cargas-documentos/${id}`, { headers: authHeader(), data: data });
        return res.data;
    }

    async sendMail(id) {
        const res = await API.post(`/cargas-documentos/${id}/email`, {}, { headers: authHeader() });
        return res.data;
    }
}

export default new CargasDocumentosService();
