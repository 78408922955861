/**
 * URLS
 */
export const BASE_URL = process.env.VUE_APP_SERVICE_BASE_URL;

export const API_URL = `${BASE_URL}/api`;
export const STORAGE_URL = `${BASE_URL}/storage`;

/**
 * PUSHER
 */
export const PUSHER_KEY = process.env.VUE_APP_PUSHER_KEY

export const PUSHER_CONFIG = {
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER
};

/**
 * NIUBIZ
 */
export const NIUBIZ_LIBRARY_URL = process.env.VUE_APP_NIUBIZ_LIBRARY_URL;

export const RECAPTCHA_PUBLIC_KEY = process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY ?? '';
