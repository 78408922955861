import EmpresasService from '../services/empresas.service';

const initialState = {
    empleados: [],
    empleadosPagination: {
        page: 1,
        per_page: 10,
        total: 0,
        total_pages: 0
    },
    isLoading: false,
    haveBeenSearched: false,
    stats: {},
};

export const empresas = {
    namespaced: true,
    state: initialState,
    actions: {
        create({ commit }, data) {
            return EmpresasService.create(data).then(
                res => {
                    commit('createSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('createFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        getStats({ commit }) {
            return EmpresasService.getStats().then(
                res => {
                    commit('getStatsSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('getStatsFailure');
                    return Promise.reject(err.response.data);
                }
            )
        },
        update({ commit }, data) {
            return EmpresasService.update(data).then(
                res => {
                    commit('updateSuccess');
                    return Promise.resolve(res);
                },
                err => {
                    commit('updateFailure');
                    return Promise.reject(err.response.data)
                }
            );
        },
        getEmpleados({ commit }, filters) {
            commit('setIsLoading', true);
            return EmpresasService.getEmpleados(filters).then(
                res => {
                    commit('getEmpleadosSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('getEmpleadosFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        setIsLoading({ commit }, value) {
            commit('setIsLoading', value);
        },
        setHasBeenSearched({ commit }, value) {
            commit('hasBeenSearched', value);
        }
    },
    mutations: {
        createSuccess(state, res) {
            //
        },
        createFailure(state) {
            //
        },
        getStatsSuccess(state, res) {
            state.stats = res.data;
        },
        getStatsFailure(state) {
            state.stats = {};
        },
        updateSuccess() {
            //
        },
        updateFailure() {
            //
        },
        getEmpleadosSuccess(state, res) {
            state.empleados = res.data;
            state.isLoading = false;
            state.empleadosPagination = res.pagination;
        },
        getEmpleadosFailure(state) {
            state.empleados = [];
            state.isLoading = false;
            state.empleadosPagination = {
                page: 1,
                per_page: 10,
                total: 0,
                total_pages: 0
            };
        },
        setIsLoading(state, value) {
            state.isLoading = value;
        },
        setHasBeenSearched(state, value) {
            state.haveBeenSearched = value;
        },
    }
}
