import authHeader from './auth-header';
import API from '@/services/base';

class TiposDocumentosEjemplosService {
    async create({ file, data }) {
        const formData = new FormData();
        formData.append('file', file);

        for (const key in data) {
            formData.append(key, data[key]);
        }

        const headers = {
            ...authHeader(),
            'Content-Type': 'multipart-form-data'
        };

        const res = await API.post(`/tipos-documentos-ejemplos`, formData, { headers });
        return res.data;
    }
}

export default new TiposDocumentosEjemplosService();
