export const publicRoutes = [
    '/login',
    '/register',
    '/reset-password',
    '/forgot-password',
    'verify-code',
    '/404',
    '/500',
    '/maintenance',
    '/coming-soon'
];
