import PaisesService from '@/services/paises.service';

const initialState = {
    data: []
};

export const paises = {
    namespaced: true,
    state: initialState,
    actions: {
        get({ commit }) {
            return PaisesService.get().then(
                res => {
                    commit('getSuccess', res.data);
                    return Promise.resolve(res.data);
                },
                err => {
                    commit('getFailure');
                    return Promise.reject(err);
                }
            );
        }
    },
    mutations: {
        getSuccess(state, paises) {
            state.data = paises;
        },
        getFailure(state) {
            state.data = [];
        },
    }
}
