import authHeader from './auth-header';
import API from '@/services/base';

class EstadosService {
    async get(instance) {
        const res = await API.get(`/estados?t=${instance}`, { headers: authHeader() });
        return res.data;
    }
}

export default new EstadosService();
