<template>
    <router-view />
</template>

<script>
import { publicRoutes } from '@/router/routes';

export default {
    metaInfo: {
        title: 'Inicio',
        titleTemplate: '%s | Gestión Humana - Grupo Verfrut',
    },
    data() {
        return {
            advantage_refresh_minutes: 1,
        };
    },
    async mounted() {
        await this.initSession();
        this.bootstrapInterval();
    },
    computed: {
        currentPath() {
            return window.location.pathname;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        timeOfInterval() {
            const expiration = this.$store.state.auth.expiration;
            const now = this.$store.state.auth.now;

            if (expiration && now) {
                const expires_time = new Date(expiration * 1000).getTime();
                const now_time = new Date(now * 1000).getTime();
                const adventage_refresh_time =
                    this.advantage_refresh_minutes * 60 * 1000;
                const timeout =
                    expires_time - now_time - adventage_refresh_time;
                if (timeout < 0) {
                    return null;
                }
                return timeout;
            } else {
                return null;
            }
        },
    },
    methods: {
        async initSession() {
            try {
                await this.$store.dispatch('auth/refresh');
                const { perfil } = await this.$store.dispatch('me/get');

                switch (perfil.descripcion) {
                    case 'Empleado':
                        !(
                            this.currentPath == '/u' ||
                            this.currentPath.startsWith('/u/')
                        ) && this.$router.push('/u');
                        break;

                    case 'Recursos Humanos':
                        !(
                            this.currentPath == '/a' ||
                            this.currentPath.startsWith('/a/')
                        ) && this.$router.push('/a');
                        break;

                    default:
                        !(
                            this.currentPath == '/account' ||
                            this.currentPath.startsWith('/account/')
                        ) && this.$router.push('/account');
                        break;
                }
            } catch (e) {
                console.log(e);
                if (!publicRoutes.includes(this.currentPath)) {
                    await this.$store.dispatch('auth/logout');
                    this.currentPath !== '/login' &&
                        this.$router.push('/login');
                }
            }
        },
        bootstrapInterval() {
            if (this.loggedIn && this.timeOfInterval) {
                setInterval(() => this.refreshToken(), this.timeOfInterval);
            }
        },
        async refreshToken() {
            try {
                await this.$store.dispatch('auth/refresh');
            } catch (e) {
                console.log(e);
                if (!publicRoutes.includes(this.currentPath)) {
                    await this.$store.dispatch('auth/logout');
                    this.currentPath !== '/login' &&
                        this.$router.push('/login');
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
