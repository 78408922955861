import EstadosService from '../services/estados.service';

const initialState = {
    data: [],
}

export const estados = {
    namespaced: true,
    state: initialState,
    actions: {
        get({ commit }, instance) {
            return EstadosService.get(instance).then(
                res => {
                    commit('getSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('getFailure');
                    return Promise.reject(err.response.data);
                }
            );
        }
    },
    mutations: {
        getSuccess(state, res) {
            state.data = res.data;
        },
        getFailure(state) {
            state.data = [];
        }
    },
};
