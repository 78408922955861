import authHeader from './auth-header';
import API from '@/services/base';

class MeService {
    async get() {
        const res = await API.get(`/me`, { headers: authHeader() });
        return res.data;
    }

    async getInfo() {
        const res = await API.get(`/me/info`, { headers: authHeader() });
        return res.data;
    }

    async getImage() {
        const res = await API.get(`/me/image`, { headers: authHeader() });
        return res.data;
    }

    async empresa() {
        const res = await API.get(`/me/empresa`, { headers: authHeader() })
        return res.data;
    }

    async getStats() {
        const res = await API.get(`/me/stats`, { headers: authHeader() });
        return res.data;
    }

    async getPerfiles() {
        const res = await API.get(`/me/perfiles`, { headers: authHeader() });
        return res.data;
    }

    async update(data) {
        const res = await API.put('/me', data, { headers: authHeader() });
        return res.data;
    }

    async updateEmail(data) {
        const res = await API.post(`/me/update-email`, data, {
            headers: authHeader(),
        });
        return res.data;
    }

    async updatePassword(data) {
        const res = await API.post(`/me/update-password`, data, {
            headers: authHeader(),
        });
        return res.data;
    }

    async uploadImage(formData) {
        const res = await API.post(`/me/image`, formData, {
            headers: authHeader(),
        });
        return res.data;
    }
}

export default new MeService();
