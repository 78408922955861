import Vue from 'vue';
import VueRouter from 'vue-router';
import PageNotFound from '../views/PageNotFound.vue';
import { publicRoutes } from '@/router/routes';

Vue.use(VueRouter);

const accountRoutes = [
    {
        path: '/account',
        component: () => import('@/views/Account'),
        children: [
            {
                path: '/',
                name: 'account-home',
                component: () => import('@/views/Account/Home'),
            },
            {
                path: 'registrar-empresa',
                name: 'account-registrar-empresa',
                component: () => import('@/views/Account/RegistrarEmpresa'),
            },
            {
                path: 'elegir-plan',
                name: 'account-elegir-plan',
                component: () => import('@/views/Account/Planes'),
            }
        ],
    }
]

const userRoutes = [
    {
        path: '/u',
        component: () => import('@/views/User'),
        children: [
            {
                path: '/',
                name: 'user-home',
                component: () => import('@/views/User/Home'),
            },
            {
                path: 'documentos',
                component: () => import('@/views/User/Documentos'),
                children: [
                    {
                        path: '/',
                        name: 'user-documentos',
                        component: () => import('@/views/User/Documentos/Home')
                    },
                    {
                        path: ':id(\\d+)',
                        name: 'user-documentos-visualizador',
                        component: () => import('@/views/User/Documentos/Visualizador')
                    }
                ]
            },
        ]
    }
];

const adminRoutes = [
    {
        path: '/a',
        component: () => import('@/views/Admin'),
        children: [
            {
                path: '/',
                name: 'admin-home',
                component: () => import('@/views/Admin/Home')
            },
            {
                path: 'trabajadores',
                component: () => import('@/views/Admin/Trabajadores'),
                children: [
                    {
                        path: '/',
                        name: 'admin-trabajadores-home',
                        component: () => import('@/views/Admin/Trabajadores/Home'),
                    },
                    {
                        path: ':persona_id(\\d+)',
                        name: 'admin-trabajadores-detail',
                        component: () => import('@/views/Admin/Trabajadores/Detail'),
                    },
                    {
                        path: 'carga',
                        component: () => import('@/views/Admin/Trabajadores/Carga'),
                        children: [
                            {
                                path: 'masiva',
                                name: 'admin-trabajadores-carga-masiva',
                                component: () => import('@/views/Admin/Trabajadores/Carga/Masiva'),
                            },
                            {
                                path: 'individual',
                                name: 'admin-trabajadores-carga-individual',
                                component: () => import('@/views/Admin/Trabajadores/Carga/Individual'),
                            }
                        ]
                    },
                    {
                        path: 'baja',
                        name: 'admin-trabajadores-baja',
                        component: () => import('@/views/Admin/Trabajadores/Baja'),
                    }
                ]
            },
            {
                path: 'documentos',
                name: 'admin-documentos',
                component: () => import('@/views/Admin/Documentos'),
                children: [
                    {
                        path: 'buscador',
                        name: 'admin-documentos-buscador',
                        component: () => import('@/views/Admin/Documentos/Buscador')
                    },
                    {
                        path: 'tipos-documentos',
                        component: () => import('@/views/Admin/Empresa/TiposDocumentos'),
                        children: [
                            {
                                path: '/',
                                name: 'admin-documentos-tipos-documentos-home',
                                component: () => import('@/views/Admin/Empresa/TiposDocumentos/Home'),
                            },
                            {
                                path: ':id(\\d+)',
                                component: () => import('@/views/Admin/Empresa/TiposDocumentos/Detail'),
                                children: [
                                    {
                                        path: '/',
                                        name: 'admin-documentos-tipos-documentos-detail-home',
                                        component: () => import('@/views/Admin/Empresa/TiposDocumentos/Detail/Home'),
                                    },
                                    {
                                        path: 'configuracion/:firmante',
                                        name: 'admin-documentos-tipos-documentos-detail-configuracion',
                                        component: () => import('@/views/Admin/Empresa/TiposDocumentos/Detail/Configuracion'),
                                    }
                                ]
                            }
                        ],
                    },
                    {
                        path: 'publicacion',
                        name: 'admin-documentos-publicacion',
                        component: () => import('@/views/Admin/Documentos/Publicacion'),
                        children: [
                            {
                                path: 'masiva',
                                name: 'admin-documentos-publicacion-masiva',
                                component: () => import('@/views/Admin/Documentos/Publicacion/Masiva'),
                            },
                            {
                                path: 'individual',
                                name: 'admin-documentos-publicacion-individual',
                                component: () => import('@/views/Admin/Documentos/Publicacion/Individual'),
                            },
                            {
                                path: 'gestion',
                                name: 'admin-documentos-publicacion-gestion',
                                component: () => import('@/views/Admin/Documentos/Publicacion/Gestion'),
                            },
                        ]
                    },
                ]
            },
            {
                path: 'empresa',
                name: 'admin-empresa',
                component: () => import('@/views/Admin/Empresa'),
                children: [
                    {
                        path: 'datos',
                        component: () => import('@/views/Admin/Empresa/Datos'),
                        children: [
                            {
                                path: '/',
                                name: 'admin-empresa-datos',
                                component: () => import('@/views/Admin/Empresa/Datos/Home')
                            },
                            {
                                path: 'datos-laborales',
                                name: 'admin-empresa-datos-datos-laborales',
                                component: () => import('@/views/Admin/Empresa/Datos/DatosLaborales')
                            }
                        ]
                    },
                    {
                        path: 'procesos',
                        name: 'admin-empresa-procesos',
                        component: () => import('@/views/Admin/Empresa/Procesos')
                    }
                ]
            },
            {
                path: 'reportes',
                name: 'admin-reportes',
                component: () => import('@/views/Admin/Reportes')
            },
            {
                path: 'integracion',
                name: 'admin-integracion',
                component: () => import('@/views/Admin/Integracion')
            }
        ]
    },
];

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login')
    },
    ...accountRoutes,
    ...userRoutes,
    ...adminRoutes,
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('@/views/ResetPassword'),
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import('@/views/ForgotPassword'),
    },
    {
        path: '/verify-code',
        name: 'VerifyCode',
        component: () => import('@/views/VerifyCode'),
    },
    {
        path: '/notificaciones',
        name: 'Notificaciones',
        component: () => import('@/views/Notificaciones'),
    },
    {
        path: '*',
        component: PageNotFound
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    }
});

router.beforeEach((to, from, next) => {
    const publicPages = publicRoutes;
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('token');

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});

export default router;
