import DatosLaboralesService from '@/services/datos-laborales.services';

const initialState = {
    data: [],
    isLoading: false
};

export const datosLaborales = {
    namespaced: true,
    state: initialState,
    actions: {
        createMany({ commit }, data) {
            return DatosLaboralesService.createMany(data).then(
                res => {
                    return Promise.resolve(res);
                },
                err => {
                    return Promise.reject(err.response.data);
                }
            );
        }
    },
    mutations: {
        createManySuccess() {
            //
        },
        createManyFailure() {
            //
        }
    }
};
