import authHeader from './auth-header';
import API from '@/services/base';

class PagosService {
    async sessionToken(data) {
        const res = await API.get(`/pagos/session-token`, { headers: authHeader(), params: data });
        return res.data;
    }
    async procesar(data) {
        const res = await API.post(`/pagos/procesar`, data, { headers: authHeader() });
        return res.data;
    }
}

export default new PagosService();
