import authHeader from './auth-header';

import API from '@/services/base';

class CamposDatosLaboralesService {
    async create({ descripcion, opciones }) {
        const res = await API.post(`/campos-datos-laborales`, { descripcion, opciones }, { headers: authHeader() });
        return res.data;
    }
    async update(id, { descripcion, opciones }) {
        const res = await API.put(`/campos-datos-laborales/${id}`, { descripcion, opciones }, { headers: authHeader() });
        return res.data;
    }
    async get() {
        const res = await API.get(`/campos-datos-laborales`, { headers: authHeader() });
        return res.data;
    }
    async show(id) {
        const res = await API.get(`/campos-datos-laborales/${id}`, { headers: authHeader() });
        return res.data;
    }
}

export default new CamposDatosLaboralesService();
